import React, { useState, useRef, useCallback, useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, message } from "antd";
import "antd/dist/antd.css";
import Bg from "./images/bgsign.png";
import Helper from "./../../extra/helper";
import SvgStore from "./images/storeSvg";
import { Row, Col, Input, Form, Button } from "antd";
import MdOTP from "./modal/otp";
import MdSucc from "./modal/success";
import Service from "./../../extra/getAPI";
import "./css/style.css";
import { Animated } from "react-animated-css";
export const AuthContext = React.createContext();
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 25,
      // color: "#009B4B",
      color: "#FB7800",
    }}
    spin
  />
);

const Index = () => {
  const [form] = Form.useForm();
  const formRef = React.createRef();
  // const [isModalVisibleFail, setIsModalVisibleFail] = useState(false);
  const [focus, setFocus] = useState({});
  const [formData, setFormData] = useState({});
  const [viewPass, setViewPass] = useState(false);
  const [viewRePass, setViewRePass] = useState(false);
  const [openOTP, setOpenOTP] = useState(false);
  const [openSucc, setOpenSucc] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mess, setMess] = useState("");

  const handleCancel = (resetForm) => {
    setOpenOTP(false);
    setOpenSucc(false);
    if (resetForm === "resetForm") {
      form.resetFields();
      setFormData({});
      setFocus({});
    }
  };

  const onFinish = (val) => {
    form
      .validateFields()
      .then(async (values) => {
        const referral_code = Helper.getParamUrlByKey("referral_code");
        if (values.password === values.repassword) {
          if (referral_code) {
            setLoading(true);
            getOTP();
          } else {
            MessageErr("Chưa có mã giới thiệu. Vui lòng kiểm tra lại!");
          }
        } else {
          MessageErr("Xác nhận mật khẩu chưa đúng!");
        }
      })
      .catch((err) => {
        // console.error("err:", err);
      });
  };

  // Thông báo lỗi dủng chung cho các page
  const MessageErr = (content) => {
    message.error(
      {
        content: content,
        style: {
          marginTop: "25px",
        },
      },
      15
    );
  };

  // Thông báo lỗi dủng chung cho các page
  const MessageSucces = (content) => {
    message.success(
      {
        content: content,
        style: {
          marginTop: "25px",
        },
      },
      15
    );
  };

  const getOTP = (recall) => {
    const data = {
      phoneNumber: formData.phoneNumber,
    };
    Service.apicall("POST", `api/v1/user/get-otp`, data)
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          if (recall) {
            MessageSucces("Gửi lại OTP thành công!");
          }
          setOpenOTP(true);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onForcus = ({ data, name }) => {
    setFocus({
      [name]: data,
    });
  };

  const onChangForm = ({ data, name }) => {
    setFormData({
      ...formData,
      [name]: data,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        formData,
        getOTP,
        MessageErr,
        MessageSucces,
        setOpenSucc,
        mess,
        setMess,
      }}
    >
      <MdSucc isModalVisible={openSucc} handleCancel={handleCancel} />
      <MdOTP isModalVisible={openOTP} handleCancel={handleCancel} />
      <Spin
        indicator={antIcon}
        // tip={
        //   <div style={{ color: "#FB7800" }}>
        //     Đang tạo tài khoản.
        //     <br />
        //     Vui lòng chờ trong giây lát!
        //   </div>
        // }
        wrapperClassName="spin-custom"
        spinning={loading}
      >
        <div
          style={{
            backgroundImage: `url(${Bg})`,
            height: "100%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            overflowX: "hidden",
            backgroundPosition: "center bottom",
            width: "100%",
          }}
        >
          <Form
            ref={formRef}
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            style={{ height: "100%" }}
          >
            <div
              style={{
                padding: "7%",
                background: "rgba(0, 0, 0, 0.4)",
                height: "100%",
                width: "100%",
              }}
            >
              <Animated
                animationIn="fadeInDown"
                animationOut="zoomOutDown"
                animationInDuration={1000}
                animationOutDuration={1000}
                isVisible={true}
              >
                <div xs={24}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: 20,
                    }}
                  >
                    <SvgStore.LogoGsale />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "5px",
                      color: "#FFFFFF",
                    }}
                  >
                    <b>Đăng ký tài khoản</b>
                  </div>
                </div>
              </Animated>
              <div xs={24} style={{ marginTop: 40 }}>
                <Animated
                  animationIn="fadeInUp"
                  animationInDuration={1000}
                  animationOutDuration={1000}
                  isVisible={true}
                >
                  <div style={css.divForm}>
                    <div
                      className="lablecus"
                      style={
                        !focus?.phoneNumber && !formData?.phoneNumber
                          ? { ...css.lable }
                          : {
                              ...css.lableFocus,
                              display: formData?.phoneNumber ? "none" : "",
                            }
                      }
                    >
                      Số điện thoại<span style={{ color: "red" }}> *</span>
                    </div>
                    <Form.Item
                      name="phoneNumber"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập đầy đủ thông tin.",
                        },
                        {
                          message: "Vui lòng nhập đúng số điện thoại.",
                          pattern: /((09|03|07|08|05)+([0-9]{8})\b)/,
                        },
                      ]}
                      style={{ ...css.FormItemsHolder, zIndex: 10 }}
                    >
                      <Input
                        type="tel"
                        onChange={(e) =>
                          onChangForm({
                            data: e.target.value,
                            name: "phoneNumber",
                          })
                        }
                        onBlur={() =>
                          onForcus({ data: false, name: "phoneNumber" })
                        }
                        onFocus={() =>
                          onForcus({ data: true, name: "phoneNumber" })
                        }
                        style={{ ...css.input }}
                        placeholder=""
                      />
                    </Form.Item>
                    <div style={css.icon}>
                      <SvgStore.PhoneInput />
                    </div>
                  </div>
                </Animated>
              </div>
              <div xs={24} style={{ marginTop: "15px" }}>
                <Animated
                  animationIn="fadeInUp"
                  animationInDuration={1000}
                  animationOutDuration={1000}
                  isVisible={true}
                >
                  <div style={css.divForm}>
                    <div
                      className="lablecus"
                      style={
                        !focus?.password && !formData?.password
                          ? { ...css.lable }
                          : {
                              ...css.lableFocus,
                              display: formData?.password ? "none" : "",
                            }
                      }
                    >
                      Mật khẩu<span style={{ color: "red" }}> *</span>
                    </div>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập đầy đủ thông tin.",
                        },
                        {
                          min: 8,
                          message: "Mật khẩu tối thiểu phải có 8 ký tự",
                        },
                      ]}
                      style={{ ...css.FormItemsHolder, zIndex: 1 }}
                    >
                      <Input
                        type={viewPass ? "text" : "password"}
                        onChange={(e) =>
                          onChangForm({
                            data: e.target.value,
                            name: "password",
                          })
                        }
                        onFocus={() =>
                          onForcus({ data: true, name: "password" })
                        }
                        onBlur={() =>
                          onForcus({ data: false, name: "password" })
                        }
                        style={{ ...css.input }}
                        placeholder=""
                      />
                    </Form.Item>
                    <div style={css.icon}>
                      <SvgStore.PassInput />
                    </div>
                    <div
                      onClick={() => setViewPass(!viewPass)}
                      style={css.iconpass}
                    >
                      {viewPass ? (
                        <SvgStore.IconpassNoView />
                      ) : (
                        <SvgStore.IconpassView />
                      )}
                    </div>
                  </div>
                </Animated>
              </div>
              <div style={{ marginTop: "15px" }}>
                <Animated
                  animationIn="fadeInUp"
                  animationInDuration={1000}
                  animationOutDuration={1000}
                  isVisible={true}
                >
                  <div style={css.divForm}>
                    <div
                      className="lablecus"
                      style={
                        !focus?.repassword && !formData?.repassword
                          ? { ...css.lable }
                          : {
                              ...css.lableFocus,
                              display: formData?.repassword ? "none" : "",
                            }
                      }
                    >
                      Xác nhận mật khẩu<span style={{ color: "red" }}> *</span>
                    </div>
                    <Form.Item
                      name="repassword"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập đầy đủ thông tin.",
                        },
                      ]}
                      style={{ ...css.FormItemsHolder, zIndex: 1 }}
                    >
                      <Input
                        type={viewRePass ? "text" : "password"}
                        onChange={(e) =>
                          onChangForm({
                            data: e.target.value,
                            name: "repassword",
                          })
                        }
                        onFocus={() =>
                          onForcus({ data: true, name: "repassword" })
                        }
                        onBlur={() =>
                          onForcus({ data: false, name: "repassword" })
                        }
                        style={{ ...css.input, textTransform: "capitalize" }}
                        placeholder=""
                      />
                    </Form.Item>
                    <div style={css.icon}>
                      <SvgStore.PassInput />
                    </div>
                    <div
                      onClick={() => setViewRePass(!viewRePass)}
                      style={css.iconpass}
                    >
                      {viewRePass ? (
                        <SvgStore.IconpassNoView />
                      ) : (
                        <SvgStore.IconpassView />
                      )}
                    </div>
                  </div>
                </Animated>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Button htmlType="submit" size="large" style={css.button}>
                  Tiếp tục
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Spin>
    </AuthContext.Provider>
  );
};

const css = {
  input: {
    // border: "1px solid #E3E1E1",
    color: "#FFFFFF",
    zIndex: 10,
    fontSize: 17,
    boxShadow: "none",
    padding: "12px 15px 12px 37px",
    borderRadius: 9,
    width: "100%",
    background: "rgba(0, 0, 0, 0.3)",
    fontWeight: 600,
  },
  inputCapcha: {
    color: "#3A3A3A",
    zIndex: 10,
    fontSize: 14,
    boxShadow: "none",
    padding: "10px 10px",
    borderRadius: 8,
    width: "100%",
  },
  FormItemsHolder: {
    width: "100%",
    borderRadius: 8,
    margin: "5px 0px",
    padding: 0,
  },
  button: {
    fontSize: 14,
    background: "#FFF",
    color: "#006838",
    width: "70%",
    textAlign: "center",
    borderRadius: 30,
    fontWeight: 700,
    height: 45,
    border: "1px solid #8b91dd",
    position: "fixed",
    bottom: "2%",
  },
  titleInput: {
    color: "#ffffff",
    textAlign: "left",
    fontWeight: 600,
    marginTop: 5,
  },
  lable: {
    position: "absolute",
    top: 16,
    zIndex: 10,
    left: 40,
    color: "#ffffff",
    fontSize: 14,
    fontWeight: 600,
  },
  lableFocus: {
    position: "absolute",
    top: 16,
    zIndex: 10,
    left: 40,
    color: "#ffffff",
    transform: "translate(0px, -27px) scale(1)",
    fontSize: 14,
    fontWeight: 600,
    zIndex: 11,
  },
  icon: {
    position: "absolute",
    top: 15,
    zIndex: 10,
    left: 10,
  },
  iconpass: {
    position: "absolute",
    top: 15,
    zIndex: 10,
    // left: 0,
    right: 10,
  },
  divForm: {
    position: "relative",
    zIndex: 1,
    // background: "rgba(0, 0, 0, 0.3)",
    borderRadius: 10,
  },
};

export default Index;
