const LogoGsale = () => (
  <svg width="84" height="118" viewBox="0 0 84 118" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2028 56.5255L40.3358 71.0493V80.2969L6.46582 60.7238V19.9891L40.3358 0.416016V9.72032L13.0469 25.4355L64.7879 55.2774L43.1724 67.7587V48.753L40.3358 47.6183V67.7587L18.1529 54.9937L15.2028 56.5255ZM43.1728 9.72039V0.416084L77.0427 19.9892V60.7239L43.1728 80.2969V71.0494L70.4616 55.2774L18.7206 25.4356L40.3361 12.9542V34.0023L43.1728 35.137V12.9542L65.2421 25.6625L68.3058 24.1874L43.1728 9.72039Z"
      fill="white"
    />
    <path
      d="M22.8434 105.563C22.8623 105.772 22.8718 105.97 22.8718 106.16C22.8907 106.349 22.9002 106.538 22.9002 106.728C22.9002 106.993 22.8907 107.248 22.8718 107.494C22.8529 107.74 22.8245 107.986 22.7866 108.232C22.6162 109.482 22.2282 110.655 21.6224 111.753C21.0356 112.832 20.2879 113.769 19.3793 114.564C18.4896 115.359 17.4674 115.984 16.3127 116.438C15.158 116.893 13.9276 117.12 12.6214 117.12C11.2017 117.12 9.85771 116.845 8.58943 116.296C7.34008 115.747 
    6.25163 115.009 5.32408 114.082C4.39653 113.135 3.65828 112.037 3.10932 110.788C2.57929 109.52 2.31428 108.166 2.31428 106.728C2.31428 105.289 2.57929 103.945 3.10932 102.696C3.65828 101.427 4.39653 100.329 5.32408 99.4018C6.25163 98.4553 7.34008 97.7076 8.58943 97.1586C9.85771 96.6097 11.2017 96.3352 12.6214 96.3352C13.7004 96.3352 14.7226 96.4961 15.688 96.8179C16.6724 97.1208 17.5715 97.5562 18.3855 98.124C19.1995 98.6919 19.9188 99.3734 20.5435 100.168C21.1871
     100.945 21.7076 101.796 22.1051 102.724H18.6126C17.988 101.664 17.1456 100.812 16.0855 100.168C15.0444 99.5248 13.8897 99.203 12.6214 99.203C11.6371 99.203 10.7095 99.4018 9.83878 99.7993C8.96802 100.197 8.21084 100.736 7.56723 101.418C6.92363 102.099 6.41253 102.894 6.03394 103.803C5.65535 104.712 5.46605 105.686 5.46605 106.728C5.46605 107.769 5.65535 108.744 6.03394 109.652C6.41253 110.561 6.92363 111.356 7.56723 112.037C8.21084 112.719 8.96802 113.258 9.83878 
     113.656C10.7095 114.053 11.6371 114.252 12.6214 114.252C13.4733 114.252 14.2872 114.101 15.0633 113.798C15.8395 113.495 16.5304 113.078 17.1361 112.548C17.7608 111.999 18.2814 111.356 18.6978 110.618C19.1332 109.879 19.4361 109.084 19.6064 108.232H12.3375V105.563H19.6632H22.8434ZM37.2577 106.756C37.3902 106.851 37.5132 106.964 37.6268 107.097C37.7404 107.21 37.8445 107.324 37.9391 107.437C38.3745 107.986 38.7153 108.592 38.9613 109.255C39.2074 109.898 39.3305 110.58 
     39.3305 111.299C39.3305 111.469 39.321 111.602 39.3021 111.697C39.2453 112.757 38.9235 113.713 38.3367 114.564C37.8066 115.359 37.1252 115.994 36.2923 116.467C35.4594 116.921 34.5318 117.148 33.5096 117.148H31.6356C30.6134 117.148 29.6859 116.921 28.853 116.467C28.02 115.994 27.3386 115.359 26.8086 114.564C26.6571 114.337 26.5057 114.072 26.3542 113.769L28.9949 111.725C29.0517 112.085 29.1653 112.425 29.3357 112.747C29.525 113.05 29.7521 113.324 30.0171 113.571C30.2821 
     113.798 30.585 113.978 30.9257 114.11C31.2665 114.243 31.6261 114.309 32.0047 114.309H33.1405C33.9166 114.309 34.5886 114.063 35.1565 113.571C35.7244 113.059 36.0651 112.435 36.1787 111.697C36.1787 111.621 36.1787 111.545 36.1787 111.469C36.1976 111.394 36.2071 111.318 36.2071 111.242C36.2071 110.826 36.1314 110.438 35.9799 110.078C35.8285 109.718 35.6203 109.406 35.3553 109.141C35.0902 108.857 34.7779 108.63 34.4182 108.46C34.0586 108.289 33.68 108.195 33.2825 108.176H31.664H31.5504C30.85 
     108.157 30.1875 108.024 29.5628 107.778C28.9571 107.513 28.4081 107.163 27.9159 106.728C27.6509 106.538 27.4238 106.311 27.2345 106.046C26.7991 105.497 26.4584 104.901 26.2123 104.257C25.9662 103.595 25.8431 102.904 25.8431 102.184V101.787C25.9378 100.708 26.2691 99.752 26.837 98.9191C27.367 98.124 28.0484 97.4994 28.8813 97.0451C29.7142 96.5718 30.6418 96.3352 31.664 96.3352H33.5096C34.5318 96.3352 35.4594 96.5718 36.2923 97.0451C37.1441 97.4994 37.835 98.124 38.3651 
     98.9191C38.4219 99.0137 38.4786 99.1178 38.5354 99.2314C38.6111 99.345 38.6774 99.4491 38.7342 99.5438L36.1503 101.56C35.9799 100.878 35.6203 100.31 35.0713 99.8561C34.5224 99.4018 33.8882 99.1746 33.1689 99.1746H32.0331C31.6545 99.1746 31.2854 99.2409 30.9257 99.3734C30.585 99.5059 30.2821 99.6952 30.0171 99.9413C29.7521 100.168 29.525 100.443 29.3357 100.765C29.1653 101.087 29.0517 101.427 28.9949 101.787C28.976 101.863 28.9665 101.938 28.9665 102.014C28.9665 102.09 
     28.9665 102.166 28.9665 102.241C28.9665 102.658 29.0422 103.046 29.1937 103.405C29.3451 103.765 29.5533 104.087 29.8184 104.371C30.0834 104.636 30.3957 104.854 30.7554 105.024C31.115 105.194 31.4936 105.289 31.8911 105.308H33.5096H33.6232C34.3236 105.327 34.9767 105.469 35.5824 105.734C36.2071 105.98 36.7655 106.321 37.2577 106.756ZM49.4288 102.156C50.4699 102.156 51.4448 102.355 52.3534 102.752C53.281 103.15 54.0855 103.689 54.767 104.371C55.4673 105.052 56.0163 105.857 
     56.4138 106.784C56.8113 107.712 57.0101 108.696 57.0101 109.737V117.091H54.0571V115.7C53.4135 116.192 52.7036 116.58 51.9275 116.864C51.1514 117.148 50.3185 117.29 49.4288 117.29C48.3877 117.29 47.4033 117.091 46.4758 116.694C45.5672 116.296 44.7721 115.757 44.0907 115.075C43.4092 114.394 42.8697 113.599 42.4722 112.69C42.0747 111.763 41.8759 110.778 41.8759 109.737C41.8759 108.696 42.0747 107.712 42.4722 106.784C42.8697 105.857 43.4092 105.052 44.0907 104.371C44.7721 
     103.689 45.5672 103.15 46.4758 102.752C47.4033 102.355 48.3877 102.156 49.4288 102.156ZM49.4288 114.337C50.0724 114.337 50.6687 114.214 51.2177 113.968C51.7855 113.722 52.2777 113.391 52.6942 112.974C53.1106 112.558 53.4419 112.075 53.688 111.526C53.934 110.958 54.0571 110.362 54.0571 109.737C54.0571 109.094 53.934 108.497 53.688 107.948C53.4419 107.381 53.1106 106.888 52.6942 106.472C52.2777 106.056 51.7855 105.724 51.2177 105.478C50.6687 105.232 50.0724 105.109 49.4288 
     105.109C48.7852 105.109 48.1795 105.232 47.6116 105.478C47.0626 105.724 46.5799 106.056 46.1635 106.472C45.747 106.888 45.4157 107.381 45.1697 107.948C44.9236 108.497 44.8005 109.094 44.8005 109.737C44.8005 110.362 44.9236 110.958 45.1697 111.526C45.4157 112.075 45.747 112.558 46.1635 112.974C46.5799 113.391 47.0626 113.722 47.6116 113.968C48.1795 114.214 48.7852 114.337 49.4288 114.337ZM60.4058 116.978V92.1896H63.302V116.978H60.4058ZM75.4215 110.618H69.7142C69.8278 111.129 
     70.0171 111.611 70.2821 112.066C70.566 112.501 70.9068 112.88 71.3043 113.201C71.7018 113.523 72.1467 113.779 72.6388 113.968C73.131 114.138 73.6516 114.224 74.2005 114.224C74.8631 114.224 75.4783 114.091 76.0462 113.826C76.633 113.561 77.1441 113.192 77.5795 112.719H78.1189H81.0152C80.9016 113.003 80.7596 113.277 80.5893 113.542C80.4378 113.788 80.2675 114.034 80.0782 114.28C79.3967 115.151 78.5449 115.852 77.5227 116.382C76.5005 116.893 75.3931 117.148 74.2005 117.148C73.1594 
     117.148 72.1845 116.959 71.2759 116.58C70.3673 116.183 69.5722 115.643 68.8908 114.962C68.2093 114.28 67.6698 113.485 67.2723 112.577C66.8937 111.668 66.7044 110.693 66.7044 109.652C66.7044 108.611 66.8937 107.636 67.2723 106.728C67.6698 105.819 68.2093 105.024 68.8908 104.342C69.5722 103.661 70.3673 103.131 71.2759 102.752C72.1845 102.355 73.1594 102.156 74.2005 102.156C75.3931 102.156 76.5005 102.421 77.5227 102.951C78.5449 103.462 79.3967 104.163 80.0782 105.052C80.7028 105.809 
     81.1571 106.699 81.4411 107.721C81.6115 108.327 81.6966 108.971 81.6966 109.652C81.6966 109.993 81.6682 110.315 81.6115 110.618H78.6584H75.4215ZM74.2005 105.081C73.273 105.081 72.4401 105.327 71.7018 105.819C70.9825 106.292 70.4335 106.926 70.0549 107.721H76.6708H78.3461C78.2515 107.57 78.1568 107.418 78.0622 107.267C77.9864 107.116 77.9013 106.974 77.8066 106.841C77.3712 106.292 76.8412 105.866 76.2165 105.563C75.5918 105.242 74.9198 105.081 74.2005 105.081Z"
      fill="white"
    />
  </svg>
);

const PhoneInput = () => (
  <svg
     width="24"
    height="24"
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="PhoneIphoneIcon"
  >
    <path fill="#FFFFFF" d="M15.5 1h-8C6.12 1 5 2.12 5 3.5v17C5 21.88 6.12 23 7.5 23h8c1.38 0 2.5-1.12 2.5-2.5v-17C18 2.12 16.88 1 15.5 1zm-4 21c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm4.5-4H7V4h9v14z"></path>
  </svg>
);

const IconpassView = () => (
  <svg
     width="24"
    height="24"
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="PhoneIphoneIcon"
  >
    <path fill="#006838" d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path>
  </svg>
);

const IconpassNoView = () => (
  <svg
     width="24"
    height="24"
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="PhoneIphoneIcon"
  >
    <path fill="#006838" d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78 3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"></path>
  </svg>
);

const PassInput = () => (
  <svg
    width="24"
    height="24"
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="LockIcon"
  >
    <path
      fill="#FFFFFF"
      d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"
    ></path>
  </svg>
);

const CodeReffeInput = () => (
  <svg width="22" height="15" viewBox="0 0 22 15" fill="none">
    <path
      d="M1.62913 0.574463C1.42298 0.574361 1.21884 0.614889 1.02836 0.69373C0.837889 0.772571 0.664822 0.88818 0.519055 1.03395C0.373288 1.17971 0.257679 1.35278 0.178838 1.54325C0.0999963 1.73373 0.0594683 1.93787 0.0595705 2.14402V13.0049C0.0595705 13.8721 0.761904 14.5745 1.62913 14.5745H19.49C19.6962 14.5746 19.9003 14.534 20.0908 14.4552C20.2813 14.3764 20.4543 14.2607 20.6001 14.115C20.7459 13.9692 20.8615 13.7961 20.9403 13.6057C21.0191 13.4152 21.0597 13.2111 21.0596 13.0049V2.14402C21.0597 1.93787 21.0191 1.73373 20.9403 1.54325C20.8615 1.35278 20.7459 1.17971 20.6001 1.03395C20.4543 0.88818 20.2813 0.772571 20.0908 0.69373C19.9003 0.614889 19.6962 0.574361 19.49 0.574463H1.62913ZM1.61513 2.13002H19.504V13.0189H1.61513V2.13002ZM3.17068 3.68557V5.24113H10.9485V3.68557H3.17068ZM3.17068 6.79669V8.35224H4.72624V6.79669H3.17068ZM6.28179 6.79669V8.35224H7.83735V6.79669H6.28179ZM9.3929 6.79669V8.35224H10.9485V6.79669H9.3929ZM3.17068 9.9078V11.4634H10.9485V9.9078H3.17068ZM12.504 9.9078V11.4634H17.9485V9.9078H12.504Z"
      fill="#25806D"
    />
  </svg>
);

const EyeInput = () => (
  <svg width="22" height="15" viewBox="0 0 22 15" fill="none">
    <path
      d="M11.0865 14.6741C13.3886 14.6741 15.4649 14.0257 17.3153 12.7289C19.1657 11.4322 20.4989 9.74932 21.3148 7.68034C20.4989 5.61136 19.1657 3.9285 17.3153 2.63174C15.4649 1.33499 13.3886 0.686612 11.0865 0.686612C8.78439 0.686612 6.70084 1.33499 4.83585 2.63174C2.97085 3.9285 1.63039 5.61136 0.814453 7.68034C1.63039 9.74932 2.97085 11.4322 4.83585 12.7289C6.70084 14.0257 8.78439 14.6741 11.0865 14.6741ZM11.0865 3.00329C12.3687 3.00329 13.4614 3.46225 14.3648 4.38018C15.2682 5.2981 15.7198 6.39816 15.7198 7.68034C15.7198 8.96253 15.2682 10.0626 14.3648 10.9805C13.4614 11.8984 12.3687 12.3574 11.0865 12.3574C9.80431 12.3574 8.70426 11.8984 7.78633 10.9805C6.8684 10.0626 6.40944 8.96253 6.40944 7.68034C6.40944 6.39816 6.8684 5.2981 7.78633 4.38018C8.70426 3.46225 9.80431 3.00329 11.0865 3.00329ZM11.0646 10.4778C11.8369 10.4778 12.4998 10.2083 13.0535 9.66918C13.6072 9.13008 13.884 8.46714 13.884 7.68034C13.884 6.89355 13.6072 6.2306 13.0535 5.6915C12.4998 5.1524 11.8369 4.88285 11.0646 4.88285C10.2924 4.88285 9.63675 5.1524 9.09765 5.6915C8.55855 6.2306 8.289 6.89355 8.289 7.68034C8.289 8.46714 8.55855 9.13008 9.09765 9.66918C9.63675 10.2083 10.2924 10.4778 11.0646 10.4778Z"
      fill="#343A40"
    />
  </svg>
);

const DoneSvg = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none">
    <path
      d="M28.3377 0.25C12.8952 0.25 0.177734 12.9675 0.177734 28.4101C0.177734 43.8526 12.8952 56.57 28.3377 56.57C43.7803 56.57 56.4977 43.8526 56.4977 28.4101C56.4977 12.6647 43.7803 0.25 28.3377 0.25ZM45.2943 16.601L20.7678 40.8246H20.4651L10.7756 24.7765C16.2259 24.7765 20.4651 30.2268 21.0707 30.8324C21.0707 30.8324 21.0706 30.8324 21.3734 30.8324L45.2943 16.601C45.2943 16.2982 44.9915 16.2982 45.2943 16.601Z"
      fill="#39B44A"
    />
  </svg>
);

const img = {
  LogoGsale,
  PhoneInput,
  PassInput,
  CodeReffeInput,
  EyeInput,
  DoneSvg,
  IconpassView,
  IconpassNoView
};

export default img;
