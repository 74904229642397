import axios from 'axios';
import * as config from './config';
import { Base64 } from 'js-base64';
import Helper from './helper';
import { message } from "antd";

async function apicall(method, url, data) {
  let token = Helper.getParamUrlByKey('token');
  try {
    const res = await axios({
      method: method,
      url: `${config.serverCore}${url}`,
      data: { data: Base64.encode(JSON.stringify(data)) },
      // data: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`,
      }
    });
    return res;
  } catch (error) {
    if (error.response) {
      message.error(
        {
          content: error.response.data.message,
          style: {
            width: '90%',
            margin:'25px auto'
          },
        },
        15
      );
    }
  };
}

export default {
  apicall
};