import React, { useContext } from "react";
import { Modal, Button, Form } from "antd";
import { AuthContext } from "./../index";
import SvgStore from "./../images/storeSvg";

const OTP = (props) => {
  const { mess } = useContext(AuthContext);
  const [form] = Form.useForm();
  const formRef = React.createRef();

  const css = {
    divButton: {
      display: "flex",
      justifyContent: "space-around",
      marginTop: "15px",
      alignItems: "center",
    },
    button: {
      width: "95%",
      height: 40,
      borderRadius: 7,
      background: "rgb(26 151 87)",
      borderColor: "rgb(26 151 87)",
      color: "#FFF",
      fontWeight: 600,
    },
  };
  return (
    <div style={{ fontSize: 14 }}>
      <Modal
        style={{
          margin: 0,
          padding: "5%",
        }}
        className="modal-custom"
        centered={true}
        footer={false}
        visible={props.isModalVisible}
        onCancel={props.handleCancel}
        closable={false}
        maskStyle={{ background: "#000000", opacity: 0.8 }}
      >
        <div style={{ textAlign: "center" }}>
          {/* <img src={HuongDan} style={{ width: "60%" }} />
           */}
          <SvgStore.DoneSvg />
          <div
            style={{
              fontSize: 14,
              marginTop: 10,
              fontWeight: 600,
              color: "black",
            }}
          >
            {mess}
          </div>

          <div style={css.divButton}>
            <a
              href="https://core.globalcare.vn/app/gsale/download"
              target="_blank"
            >
              <Button
                htmlType="submit"
                style={css.button}
                onClick={() => props.handleCancel()}
              >
                <b>Tiếp tục</b>
              </Button>
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default OTP;
