import React, { useContext, useState, useEffect } from "react";
import { Modal, Button, Form } from "antd";
import HuongDan from "./../images/otp.png";
import { AuthContext } from "./../index";
import OtpInput from "react-otp-input";
import Service from "./../../../extra/getAPI";
import Helper from "./../../../extra/helper";

const OTP = (props) => {
  const { formData, getOTP, MessageSucces, MessageErr, setMess, setOpenSucc} = useContext(AuthContext);
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const [otp, setOtp] = useState("");
  const [time, setTime] = useState(0);
  const [disa, setDisa] = useState(true);
  const [loading, setLoading] = useState(false);

  const onFinish = (val) => {
    form
      .validateFields()
      .then(async (values) => {
        const referral_code = Helper.getParamUrlByKey("referral_code");
        if (referral_code) {
          checkOTP();
        } else {
          MessageErr("Chưa có mã giới thiệu. Vui lòng kiểm tra lại!");
        }
      })
      .catch((err) => {
        // console.error("err:", err);
      });
  };

  const getOtp = (value) => {
    if (value.length === 6) {
      setDisa(false);
    } else {
      setDisa(true);
    }
    setOtp(value);
  };

  useEffect(() => {
    Countdown(time);
  }, [time]);

  const Countdown = (secs) => {
    if (secs > 0) {
      setTimeout(() => {
        setTime(secs - 1);
      }, 1000);
    }
  };

  const checkOTP = () => {
    setLoading(true);
    const data = {
      phoneNumber: formData.phoneNumber,
      otpCode: otp,
    };
    Service.apicall("POST", `api/v1/user/verify-otp`, data)
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          Register();
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const Register = () => {
    setLoading(true);
    const data = {
      username: formData.phoneNumber,
      password: formData.password,
      role_id: 3,
      fullname: formData?.phoneNumber,
      phone_number: formData.phoneNumber,
      referral_code: Helper.getParamUrlByKey("referral_code"),
    };
    Service.apicall("POST", `api/v1/user/create?utm_source=mobile-app`, data)
      .then((res) => {
        setLoading(false);
        if (res?.data?.statusCode === 200) {
          setMess(res?.data?.message);
          // MessageSucces(res?.data?.message);
          props.handleCancel("resetForm");
          setOtp("");
          setDisa(true);
          setOpenSucc(true);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const css = {
    divButton: {
      display: "flex",
      justifyContent: "space-around",
      marginTop: "15px",
      alignItems: "center",
    },
    button: {
      width: "95%",
      height: 40,
      borderRadius: 7,
      background: "rgb(26 151 87)",
      borderColor: "rgb(26 151 87)",
      color: "#FFF",
      fontWeight: 600,
    },
  };
  return (
    <div style={{ fontSize: 14 }}>
      <Modal
        style={{
          margin: 0,
          padding: "5%",
        }}
        className="modal-custom"
        centered={true}
        footer={false}
        visible={props.isModalVisible}
        onCancel={props.handleCancel}
        closable={false}
        maskStyle={{ background: "#000000", opacity: 0.8 }}
      >
        <Form
          ref={formRef}
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          // style={{ height: "100%" }}
        >
          <div style={{ textAlign: "center" }}>
            <img src={HuongDan} style={{ width: "60%" }} />
            <div
              style={{
                fontSize: 18,
                marginTop: 10,
                fontWeight: 600,
                color: "black",
              }}
            >
              Xác minh OTP
            </div>
            <div style={{ fontSize: 13, marginTop: 10, color: "black" }}>
              Nhập mã OTP đã gửi tới {formData?.phoneNumber?.slice(0, 3)}
              *****
              {formData?.phoneNumber?.slice(8, 10)}
            </div>
            <div style={{ width: "90%", margin: "5%" }}>
              <OtpInput
                inputType={"number"}
                value={otp}
                onChange={(e) => getOtp(e)}
                numInputs={6}
                // renderSeparator={<span>-</span>}
                renderInput={(props) => <input id="otpCustomm" {...props} />}
                inputStyle={{
                  width: "100%",
                  fontSize: 20,
                  marginRight: "2%",
                  border: "none",
                  borderBottom: "2px solid #DCDCDC",
                  fontWeight: 600,
                  color: "black",
                }}
              />
            </div>
            <div
              style={{
                textAlign: "center",
                margin: "15% 0px 50% 0px",
                color: "rgb(147 147 147)",
              }}
            >
              Bạn không nhận được OTP? <br />
              {time === 0 ? (
                <b
                  onClick={() => {
                    setTime(35);
                    getOTP("recall");
                  }}
                  style={{ color: "rgb(26 151 87)" }}
                >
                  Gửi lại mã OTP
                </b>
              ) : (
                <b style={{ color: "rgb(26 151 87)" }}>{time}</b>
              )}
            </div>
            <div style={css.divButton}>
              <Button
                disabled={disa}
                loading={loading}
                htmlType="submit"
                style={css.button}
              >
                <b>Xác nhận</b>
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default OTP;
